import { Controller } from "stimulus"
import Cropper from "cropperjs"
import "cropperjs/dist/cropper.css"

export default class extends Controller {
  static targets = ["image", "model"];

  connect() {
    this.initCropper();
  }

  destroyCropper() {
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }
  }

  initCropper() {
    this.destroyCropper();

    // Establecer el atributo crossOrigin
    this.imageTarget.crossOrigin = 'anonymous';

    let _this = this;
    this.cropper = new Cropper(this.imageTarget, {
      aspectRatio: 1 / 1,
      viewMode: 1,
      checkCrossOrigin: false,  // También puedes intentar eliminar esta línea para ver si eso resuelve el problema
      crop(event) {
        _this.updatePreview(event);
        _this.crop_x().value = event.detail.x;
        _this.crop_y().value = event.detail.y;
        _this.crop_width().value = event.detail.width;
        _this.crop_height().value = event.detail.height;
      }
    });
  }

  updatePreview(event) {
    const canvas = this.cropper.getCroppedCanvas({
      width: 200,
      height: 200
    });
    const previewImage = document.getElementById('croppedImagePreview');
    previewImage.src = canvas.toDataURL('image/png');
  }

  crop_x() {
    if (!this._crop_x) {
      this._crop_x = document.createElement("input");
      this._crop_x.name = `${this.modelTarget.value}[crop_x]`;
      this._crop_x.type = "hidden";
      this.imageTarget.parentNode.insertBefore(this._crop_x, this.imageTarget.nextSibling);
    }
    return this._crop_x;
  }

  crop_y() {
    if (!this._crop_y) {
      this._crop_y = document.createElement("input");
      this._crop_y.name = `${this.modelTarget.value}[crop_y]`;
      this._crop_y.type = "hidden";
      this.imageTarget.parentNode.insertBefore(this._crop_y, this.imageTarget.nextSibling);
    }
    return this._crop_y;
  }

  crop_width() {
    if (!this._crop_width) {
      this._crop_width = document.createElement("input");
      this._crop_width.name = `${this.modelTarget.value}[crop_width]`;
      this._crop_width.type = "hidden";
      this.imageTarget.parentNode.insertBefore(this._crop_width, this.imageTarget.nextSibling);
    }
    return this._crop_width;
  }

  crop_height() {
    if (!this._crop_height) {
      this._crop_height = document.createElement("input");
      this._crop_height.name = `${this.modelTarget.value}[crop_height]`;
      this._crop_height.type = "hidden";
      this.imageTarget.parentNode.insertBefore(this._crop_height, this.imageTarget.nextSibling);
    }
    return this._crop_height;
  }
}
